"use client";
/* eslint-disable @next/next/no-img-element */

import { Spacer } from "@/components/lego/Spacer";
import { NotFoundSvg } from "@/components/shared/NotFoundSvg";
import { Button } from "@/components/ui/button";
import { Link } from "@/lib/i18n";
import { Metadata } from "next";
import * as m from "@/paraglide/messages.js";

export async function generateMetadata(): Promise<Metadata> {
  return {
    title: m.error_title(),
    description: m.error_title(),
    openGraph: {
      title: m.error_title(),
      description: m.error_title(),
    },
  };
}

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  // todo to send error in db
  return (
    <div className="mx-auto md:pl-10 md:pr-10 flex  items-center flex-col -mt-[50px]">
      <img src="/images/error.png" width={300} alt="error" />
      <Spacer y={50} />

      <h2 className="text-text1 text-3xl font-bold">{m.error_description()}</h2>
      <Spacer y={20} />

      <Link href="/">
        <Button>{m.not_found_go_home()}</Button>
      </Link>
      <Spacer y={150} />
    </div>
  );
}
